import * as React from 'react'
import { theme } from '../../theme'

// -- Components --
import { Title } from '../../components/Title'
import { Button } from '../../components'
import * as Hero from '../../components/Hero'
import { LogoGroup, Logo } from '../../components/LogoGroup'
import { Excerpt, ExcerptCopy, ExcerptH3 } from '../../components/Excerpt'
import { BubbleImage } from '../../components/BubbleImage'
import { AwardsAndFeatures } from '../../components/AwardsAndFeatures'
import { BulletList, BulletListItem } from '../../components/BulletList'

// -- Layout --
import { PageLayout } from '../../layouts/PageLayout'
import {
  LargeBullet,
  LargeBulletContent,
  LargeBulletP,
  LargeBulletH4,
  LargeBulletImage,
  LargeBulletH3
} from '../../components/LargeBullet'
import { LargeCallToAction } from '../../modules/LargeCallToAction'

// -- Images --
import backgroundImageSrc from '../../images/hero/hero-university.jpg'
import circleUniSrc from '../../images/industries/universities/circle-hp-uni.jpg'
import contactUsImgSrc from '../../images/contact-us.png'
import facilitiesMirroredSrc from '../../images/industries/universities/img-bubble-facilities-mirrored.jpg'

// Icons
import zeroTargetSrc from '../../images/icons/icon-uni-zero-target.svg'
import dataLedSrc from '../../images/icons/icon-uni-data-led.svg'
import savingsSrc from '../../images/icons/icon-uni-savings.svg'
import wellBeingSrc from '../../images/icons/icon-uni-wellbeing.svg'
import manageSrc from '../../images/icons/icon-uni-manage.svg'

// Logos
import anuLogoSrc from '../../images/logos/logo-australian-national-university@2x.png'
import pennStateLogoSrc from '../../images/logos/logo-penn-state@2x.png'
import rmitLogoSrc from '../../images/logos/logo-rmit-university@2x.png'
import canberraUniSrc from '../../images/logos/logo-university-of-canberra@2x.png'

const smartBuildingAnalyticsContent = [
  {
    heading: 'Achieve ambitious net-zero targets',
    content:
      'Address sustainability targets and operating in the new normal by arming yourself with the insights you need to make smarter decisions. Balance improved indoor environment quality (IEQ) with reduced carbon emissions and energy consumption.',
    items: [
      'Payback between 18 months and two years',
      'Energy savings of 10-15%'
    ],
    icon: zeroTargetSrc
  },
  {
    heading: 'Data-led decision making',
    content:
      'With a single source of truth, Bueno’s smart building analytics empowers teams to make informed decisions.',
    items: [
      'Optimise team efficiency',
      'Benchmark and measure maintenance performance',
      'Benchmark and measure asset and portfolio performance'
    ],
    icon: dataLedSrc
  },
  {
    heading: 'Generate tangible operational and energy savings',
    content:
      'By capturing big data from your campuses built environment, our smart building analytics system enables decision-makers to proactively identify problems, analyse performance and act on insights with ease.  Our customers achieve on average:',
    items: [
      'Payback between 18 months and two years',
      'Energy savings of 10-15%'
    ],
    icon: savingsSrc
  },
  {
    heading: 'Student and staff health and wellbeing',
    content:
      'Increase comfort and satisfaction levels for your students and staff by:',
    items: [
      'Minimising disruption such as HVAC or elevator outages across your campuses.',
      'Analyse, benchmark and improve indoor environment quality through our data-led insights.'
    ],
    icon: wellBeingSrc
  },
  {
    heading: 'Manage risk and compliance',
    content:
      'With real-time visibility, ensure your campuses assets and equipment remain at optimal performance by:',
    items: [
      'Tracking regulatory requirements',
      'Manage and benchmark service delivery against SLA’s',
      'Reporting on asset compliance',
      'Proactively identify and mitigate potential risks'
    ],
    icon: manageSrc
  }
]

const Universities: React.FC = () => {
  return (
    <PageLayout>
      <Title title="Analytics for Universities - Bueno" />

      <Hero.Wrapper src={backgroundImageSrc}>
        <Hero.LayoutContent>
          <Hero.LayoutHeading>
            <Hero.Heading>
              Creating greener, more energy-efficient campuses
            </Hero.Heading>
          </Hero.LayoutHeading>
          <div style={{ marginTop: `${theme.spacing.ultraLoose}` }}>
            <LogoGroup>
              <Logo src={anuLogoSrc} alt="Australian National University" />
              <Logo src={rmitLogoSrc} alt="RMIT University" />
              <Logo src={pennStateLogoSrc} alt="Penn State University" />
              <Logo src={canberraUniSrc} alt="University of Canberra" />
            </LogoGroup>
          </div>
        </Hero.LayoutContent>
      </Hero.Wrapper>

      <div style={{ marginTop: `${theme.spacing.extraLoose}` }}>
        <Excerpt>
          <ExcerptH3>Bueno’s Smart Building Analytics</ExcerptH3>
          <ExcerptCopy>
            Bueno’s Smart Building Analytics solution enables you to adopt
            data-driven decision-making to optimize your campus's performance so
            you can deliver a better quality of service to your students and
            staff. Achieve ambitious net-zero goals, reduce operating costs,
            improve asset optimization all whilst creating a sustainable future.
          </ExcerptCopy>
        </Excerpt>
      </div>

      <div style={{ marginTop: '56px' }}>
        <BubbleImage src={circleUniSrc} imagePosition="right" maxHeight={900}>
          {smartBuildingAnalyticsContent.map(listItem => (
            <LargeBullet key={listItem.heading}>
              <LargeBulletImage src={listItem.icon} />
              <LargeBulletContent>
                <LargeBulletH4>{listItem.heading}</LargeBulletH4>
                <LargeBulletP>{listItem.content}</LargeBulletP>
                {listItem.items != null && (
                  <BulletList style={{ marginTop: `${theme.spacing.default}` }}>
                    {listItem.items.map(bulletPoint => (
                      <BulletListItem key={bulletPoint}>
                        {bulletPoint}
                      </BulletListItem>
                    ))}
                  </BulletList>
                )}
              </LargeBulletContent>
            </LargeBullet>
          ))}
        </BubbleImage>
      </div>

      <div style={{ marginTop: '56px' }}>
        <BubbleImage
          src={facilitiesMirroredSrc}
          textPosition="center"
          imagePosition="left"
          maxHeight={640}
        >
          <LargeBullet>
            <LargeBulletContent>
              <LargeBulletH3>
                Facility management & asset optimization
              </LargeBulletH3>
              <LargeBulletP>
                Take a proactive and centralized approach to campus building
                management. Collect and monitor in real-time building data sets
                from HVAC, elevators, fire systems, and water treatment,
                empowering your service technicians to work smarter, not harder.
              </LargeBulletP>
              <BulletList>
                <BulletListItem>
                  Improving asset reliability and extend lifecycle of equipment;
                </BulletListItem>
                <BulletListItem>Identify root cause diagnostics</BulletListItem>
                <BulletListItem>Optimise maintenance schedules</BulletListItem>
                <BulletListItem>
                  Manage end-to-end warranty lifecycle
                </BulletListItem>
              </BulletList>
              <LargeBulletH4>Be at the forefront</LargeBulletH4>
              <LargeBulletP>
                Leverage data and insights from your campus into a living lab
                for research and coursework for your students. Create a core
                business asset for your institution as we optimize your
                operations and help you achieve your net-zero goals.
              </LargeBulletP>
            </LargeBulletContent>
          </LargeBullet>
        </BubbleImage>
      </div>

      <div style={{ marginTop: '56px' }}>
        <LargeCallToAction
          title="Trusted & passionate team of experts"
          body="We have the most experienced and trusted team coupled with market-leading software to support your building optimization journey.
        Learn more about the product features and functionality and services."
          image={contactUsImgSrc}
          callToAction={<Button to="/contact">Talk to an expert</Button>}
        />
      </div>

      <div style={{ marginTop: '56px' }}>
        <AwardsAndFeatures />
      </div>
    </PageLayout>
  )
}

export default Universities
